/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const consoleStyleTitle = `
  padding-top: 60px;
  font-size:34px;
  font-weight:200;
  letter-spacing:0.02em;
  line-height:1.4em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: #f1647d;
  color: #fff6b9;
`;

const consoleStyleText = `
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 1.64em;
  font-family: monospace;
  background-color: #fff6b9;
  color: #f1647d;
`;

console.log('%c Shophilist                                                          ', consoleStyleTitle); // prettier-ignore
console.log('%c This site is built with Gatsby and the Shophilist  ', consoleStyleText); // prettier-ignore
console.log('%c App is made with React Native.                     ', consoleStyleText); // prettier-ignore
console.log('%c If you got some questions you can email us at      ', consoleStyleText); // prettier-ignore
console.log('%c support@shophilist.com                             ', consoleStyleText); // prettier-ignore
console.log('%c /J                                                 ', consoleStyleText); // prettier-ignore
